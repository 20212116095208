const events = {};

// Unique ID generator for each listener
let listenerId = 0;

// Function to register an event listener
export const onEvent = (eventName, callback) => {
  // Generate a unique ID for this listener
  const id = listenerId++;

  // Check if the event name already exists in the events map
  if (!events[eventName]) {
    // If not, create an empty array for the event
    events[eventName] = [];
  }

  // Add the callback along with its ID to the list of listeners for this event
  events[eventName].push({ id, callback });

  // Return the ID so it can be used for removal
  return id;
};

// Function to fire an event
export const fireEvent = (eventName, payload) => {
  // Check if the event name exists in the events map
  if (events[eventName]) {
    // Call all the callbacks registered for this event with the payload
    events[eventName].forEach((listener) => {
      try {
        listener.callback(payload);
      } catch (error) {
        console.warn('ccauth', 'failed to fire listener for', eventName);
      }
    });
  }
};

// Function to remove an event listener by reference
export const offEvent = (id) => {
  // Iterate over all event names in the events map
  for (const eventName in events) {
    if (events[eventName]) {
      // Filter out the listener with the specified ID from the list
      events[eventName] = events[eventName].filter((listener) => listener.id !== id);

      // If no more listeners are left for the event, delete the event name from the map
      if (events[eventName].length === 0) {
        delete events[eventName];
      }
    }
  }
};
