import { utilWildcard, getStoredRedirectUrl } from './utils';

const getDefaultConfig = (mockPort) => ({
  localhost: {
    idp: `http://localhost:${mockPort || '9191'}/identityprovider`,
    clientId: 'mockClientId',
    clientSecret: '!verysecret123!',
    azureClientId: 'ba9bbe18-94df-4806-9ab4-c8e53bb60820',
    azureClientIdUserOICD: '47299a88-c1ec-45f9-97e1-0e19b190c4e5',
    appAuthObjects: [
      { appId: 'energy', allowedRedirects: ['cup.mobile.volvo.charging://signin'], clientId: 'ngdyna', clientSecret: 'ngdyna123' },
      { appId: 'energy1', allowedRedirects: ['http://localhost:1180/sso.html'], clientId: 'ngdyna', clientSecret: 'ngdyna123' },
      {
        appId: 'vcapp',
        allowedRedirects: [
          'com.volvo.VolvoConnect://oauth2callback',
          'com.volvogroup.volvoconnect:/oauth2callback',
          'https://test/*',
          'com.volvogroup.volvoconnect://oauth2callback',
          'com.test://jjjdj',
        ],
        clientId: 'vcapp',
        clientSecret: 'e`+k6W,3^TP.yp}F',
      },
      { clientId: 'ngdyna', clientSecret: 'ngdyna123', appId: 'native', allowedRedirects: ['https://auth.expo.io/*'] },
      { allowedRedirects: ['https://electrician-app-qa.azurewebsites.net/oauth2/callback'], clientId: 'volvoenergyportal', appId: 'volvoenergy' },
      {
        appId: 'ssopoc',
        allowedRedirects: ['https://s3.eu-west-1.amazonaws.com/volvoconnect.iot1.shared.eu-west-1.qa.aws.vgthosting.net/ssopoc/sso.html'],
        renewalRedirect: 'https://s3.eu-west-1.amazonaws.com/volvoconnect.iot1.shared.eu-west-1.qa.aws.vgthosting.net/ssopoc/sso.html',
        clientId: 'ngdyna',
        clientSecret: 'ngdyna123',
      },
      { allowedRedirects: ['exp://localhost:19000/signin'], clientId: 'ngdyna', clientSecret: 'ngdyna123', appId: 'exp' },
    ],
    origin: 'http://localhost:1337',
  },
  localhost_app: {
    idp: `http://localhost:${mockPort || '9191'}/identityprovider`,
    clientId: 'mockClientId',
    clientSecret: '!verysecret123!',
    azureClientId: 'ba9bbe18-94df-4806-9ab4-c8e53bb60820',
    allowedRedirects: ['com.volvo.VolvoConnect://oauth2callback', 'com.volvogroup.volvoconnect:/oauth2callback'],
    appAuthObjects: [
      { appId: 'energy', allowedRedirects: ['cup.mobile.volvo.charging://signin'], clientId: 'ngdyna', clientSecret: 'ngdyna123' },
      {
        appId: 'vcapp',
        allowedRedirects: [
          'com.volvo.VolvoConnect://oauth2callback',
          'com.volvogroup.volvoconnect:/oauth2callback',
          'https://test/*',
          'com.volvogroup.volvoconnect://oauth2callback',
          'com.test://jjjdj',
        ],
        clientId: 'vcapp',
        clientSecret: 'e`+k6W,3^TP.yp}F',
      },
      { clientId: 'ngdyna', clientSecret: 'ngdyna123', appId: 'native', allowedRedirects: ['https://auth.expo.io/*'] },
      { allowedRedirects: ['https://electrician-app-qa.azurewebsites.net/oauth2/callback'], clientId: 'volvoenergyportal', appId: 'volvoenergy' },
      {
        appId: 'ssopoc',
        allowedRedirects: ['https://s3.eu-west-1.amazonaws.com/volvoconnect.iot1.shared.eu-west-1.qa.aws.vgthosting.net/ssopoc/sso.html'],
        renewalRedirect: 'https://s3.eu-west-1.amazonaws.com/volvoconnect.iot1.shared.eu-west-1.qa.aws.vgthosting.net/ssopoc/sso.html',
        clientId: 'ngdyna',
        clientSecret: 'ngdyna123',
      },
      { allowedRedirects: ['exp://localhost:19000/signin'], clientId: 'ngdyna', clientSecret: 'ngdyna123', appId: 'exp' },
    ],
  },
});

const resolveConfig = (config, app) => {
  if (app) {
    return config[`${location.hostname}_app`] || config[location.hostname];
  }
  return config[location.hostname];
};

const validateRedirectInAuthObject = (authObject, redirectUri) => {
  if (authObject.allowedRedirects) {
    return !!authObject.allowedRedirects.find((allowedRedirect) => utilWildcard(redirectUri, allowedRedirect));
  }

  return false;
};

export const buildConfig = ({ mockPort, redirectURI, app, lambdaConfig }) => {
  const config = lambdaConfig || resolveConfig(getDefaultConfig(mockPort), app);
  console.debug('ccauth', 'buildConfig', 'input config object');
  if (config.idp) {
    console.debug('ccauth', 'buildConfig', 'lambda config contains idp');
    const decodedRedirectUri = decodeURIComponent(redirectURI || getStoredRedirectUrl());
    console.debug('ccauth', 'buildConfig', decodedRedirectUri);
    const appAuthObject = config.appAuthObjects && config.appAuthObjects.find((authObject) => validateRedirectInAuthObject(authObject, decodedRedirectUri));
    // TODO: is the appAuthObjects.find really correct?
    return {
      ...config,
      origin: config.origin || location.origin,
      ...appAuthObject,
      redirectURI,
    };
  }
  return resolveConfig(config, app);
};

export default buildConfig;
