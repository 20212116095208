import createAuthIframeCall from './createAuthIframeCall';

// TODO: similar to azureAuthorizeAndGetIDTokenPromise

const authorizeFetchAndStoreToken = async ({ scope, fetchAndStoreTokenUsingCode, buildAuthorizeUrl }) => {
  // If body does not exist just authorize the entire page, no need to be silent
  if (!document.body) {
    return setTimeout(() => {
      location.replace(buildAuthorizeUrl(scope, `${location.origin}${location.pathname}`));
    }, 50);
  }

  // Silent
  const renewalRedirect = `${location.origin}${location.pathname}?auth_stop=true`;
  const url = buildAuthorizeUrl(scope, renewalRedirect);
  const loc = await createAuthIframeCall(url, true);
  if (loc?.href?.includes?.(renewalRedirect)) {
    const locSearchParams = new URLSearchParams(loc.search);
    return fetchAndStoreTokenUsingCode(renewalRedirect, locSearchParams.get('code'), locSearchParams.get('state'));
  }
  throw new Error(loc);
};

const makeAuthorizeFetchAndStoreToken =
  ({ fetchAndStoreTokenUsingCode, buildAuthorizeUrl }) =>
  (scope) =>
    authorizeFetchAndStoreToken({
      scope,
      fetchAndStoreTokenUsingCode,
      buildAuthorizeUrl,
    });

export default makeAuthorizeFetchAndStoreToken;
