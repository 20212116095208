import { parseJwt, setLoginHint } from '../utils';
import FetchTokenError from '../errors/fetchTokenError';
import RefreshTokenError from '../errors/refreshTokenError';

const fetchAndStoreIDToken = async ({ formBody, exceptionType, azureIdp }) => {
  const response = await fetch(`${azureIdp}/token`, {
    method: 'POST',
    Origin: location.origin,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Accept: 'application/json',
    },
    body: formBody.join('&'),
  });

  if (response.ok) {
    const { id_token, refresh_token } = await response.json();
    const jwt = parseJwt(id_token);
    setLoginHint(jwt.email);
    const idTokenData = {
      idToken: id_token,
      expirationTime: jwt.exp,
      user: jwt.email,
    };
    // Save refresh token in local storage, to be used for refreshing the token an any tab
    localStorage.setItem('ccauth-oicd-refresh-token', refresh_token);
    sessionStorage.setItem('sessionIDToken', JSON.stringify(idTokenData));
    return idTokenData;
  }
  const { error_description } = await response.json();
  console.error('ccauth', 'IDToken', `fetching ID token error: ${error_description}, redirect to login!`);
  throw new exceptionType(error_description);
};

const makeIDTokenFunctions = ({ getAzureIdp, getAzureClientId }) => {
  const fetchIDToken = (redirectUrl, code) => {
    console.debug('ccauth', 'fetchIDToken', redirectUrl, code);
    const codeVerifier = sessionStorage.getItem('ccauth-code-verifier');

    const formBody = [
      `code=${code}`,
      `grant_type=authorization_code`,
      `client_id=${getAzureClientId()}`,
      `redirect_uri=${redirectUrl}`,
      `code_verifier=${codeVerifier}`,
    ];
    return fetchAndStoreIDToken({ formBody, exceptionType: FetchTokenError, azureIdp: getAzureIdp() });
  };

  const refreshIDToken = (redirectUrl, code) => {
    console.debug('ccauth', 'refreshIDToken', redirectUrl, code);
    // If we have a code pick up a new idtoken if not do a refresh_token call with refresh_token from local storage
    const formBody = ['grant_type=refresh_token', `client_id=${getAzureClientId()}`, `refresh_token=${localStorage.getItem('ccauth-oicd-refresh-token')}`];
    return fetchAndStoreIDToken({ formBody, exceptionType: RefreshTokenError, azureIdp: getAzureIdp() });
  };

  return { fetchIDToken, refreshIDToken };
};

export default makeIDTokenFunctions;
