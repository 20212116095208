export const REDIRECT = 'redirect';
export const MANAGE_TOKENS = 'manage_tokens';
export const SKIP = 'skip';
export const requiresAction = ({ noAuth, hasError, isEmergencyMode, isNoAuthPath, isLogin, isIFrame }) => {
  const authDisabled = isNoAuthPath || isEmergencyMode || noAuth;

  if (!authDisabled) {
    const shouldRedirectToLogin = !isLogin && !isIFrame && hasError;

    if (shouldRedirectToLogin) {
      console.debug('ccsdk', 'ccauth', 'will redirect to login', {
        hasError,
        isNoAuthPath,
        isLogin,
        isIFrame,
        isEmergencyMode,
        noAuth,
      });
      return REDIRECT;
    }
    console.debug('ccsdk', 'ccauth', 'will manage tokens', {
      isNoAuthPath,
      isEmergencyMode,
      noAuth,
    });
    return MANAGE_TOKENS;
  }
  return SKIP;
};
