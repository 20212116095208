import { verifyPartnerAccessToken, getPartnerTokenData } from '../utils';

const makeManagePartnerToken = ({ manageTokens, fetchAndStorePartnerToken }) => {
  return async (audience) => {
    if (verifyPartnerAccessToken(audience)) {
      return getPartnerTokenData(audience);
    }

    await manageTokens();
    return fetchAndStorePartnerToken(audience);
  };
};

export default makeManagePartnerToken;
