import FetchTokenError from '../errors/fetchTokenError';

const fetchAndStorePartnerToken = async ({ audience, subjectToken, endpoint, clientId, idp }) => {
  // Based on grant type be decode if param code or assertion needed, thus no other grant_type supported for now
  const formBody = [];
  formBody.push(`client_id=${clientId}`);
  formBody.push(`audience=${audience}`);
  formBody.push('grant_type=urn:ietf:params:oauth:grant-type:token-exchange');
  formBody.push('subject_token_type=urn:ietf:params:oauth:token-type:access_token');
  formBody.push('scope=introspection');
  formBody.push(`subject_token=${subjectToken}`);

  const response = await fetch(`${idp}${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Accept: 'application/json',
    },
    body: formBody.join('&'),
  });

  if (response.ok) {
    const { access_token: accessToken, expires_in: expiresIn, scope } = await response.json();
    const tokenData = {
      accessToken,
      expiresIn,
      scope,
      tokenRegistered: new Date().toISOString(),
    };
    sessionStorage.setItem(`sessionToken-${audience}`, JSON.stringify(tokenData));
    return tokenData;
  }

  const { error_description } = await response.json();
  throw new FetchTokenError(error_description);
};

const makeFetchAndStorePartnerToken =
  ({ clientId, idp, manageTokens }) =>
  async (audience, endpoint = '/token.oauth2') => {
    const { accessToken } = await manageTokens();
    return fetchAndStorePartnerToken({
      audience,
      subjectToken: accessToken,
      endpoint,
      clientId,
      idp,
    });
  };

export default makeFetchAndStorePartnerToken;
