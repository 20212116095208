import { generateAndStoreTispState } from '../utils';

const buildAuthorizeUrl = ({ scope, redirectUrl, idp, clientId, validateRedirectUrl }) => {
  const endpoint = `${idp}/oauth/authorize`;
  const state = generateAndStoreTispState(scope);
  console.debug('ccauth', 'buildAuthorizeUrl', 'setting ccauth-state', state);
  sessionStorage.setItem('ccauth-state', state);
  const authorizeUrl = `${endpoint}?response_type=code&redirect_error_on_not_logged_in=true&client_id=${clientId}&scope=${scope}&redirect_uri=${validateRedirectUrl(
    redirectUrl,
  )}&state=${state}`;
  console.debug('ccauth', 'buildAuthorizeUrl', 'built authorize URL', authorizeUrl);
  return authorizeUrl;
};

const makeBuildAuthorizeUrl =
  ({ idp, clientId, validateRedirectUrl }) =>
  (scope, redirectUrl) =>
    buildAuthorizeUrl({
      scope,
      redirectUrl,
      idp,
      clientId,
      validateRedirectUrl,
    });

export default makeBuildAuthorizeUrl;
