import { getAuthMethod, getIdTokenData, verifySessionIdToken, getLoginHint } from '../utils';
import { TISP_LOGIN } from '../consts';

const authorizeWithScope = async ({
  scope,
  codeChallenge,
  redirectUrl,
  redirectToApp,
  redirectCallback,
  getAzureAuthorizeURL,
  validateRedirectUrl,
  fetchAndStoreTokenUsingIdToken,
  refreshIDToken,
  buildAuthorizeUrl,
  createAuthStore,
}) => {
  console.debug('ccauth', 'authorizeWithScope', 'running authorizeWithScope', scope, codeChallenge, redirectUrl);
  try {
    const authStore = createAuthStore();
    const useScope = scope || authStore.getSelectedOrgId();
    // Never store default scope
    if (useScope !== 'default') {
      authStore.setSelectedOrgId(useScope);
    }

    // We do not have any scope, just give upp
    if (!(useScope && getAuthMethod())) {
      redirectCallback(false, redirectUrl, codeChallenge);
    } else if (getAuthMethod() !== TISP_LOGIN) {
      console.debug('ccauth', 'authorizeWithScope', 'user is Azure user');
      const clientCodeChallenge = sessionStorage.getItem('ccauth-client-code-challenge') || codeChallenge;
      if (clientCodeChallenge) {
        console.debug('ccauth', 'authorizeWithScope', 'user is Azure user and we have a code client challenge', clientCodeChallenge);
        sessionStorage.removeItem('ccauth-client-code-challenge');
        // OICD auth code requested, redirect to Azure AD
        window.location.replace(
          getAzureAuthorizeURL({
            redirectUrl: validateRedirectUrl(redirectUrl),
            codeChallenge: clientCodeChallenge,
            state: `${getAuthMethod()}_${useScope}`,
            loginHint: getLoginHint(),
          }),
        );
      } else {
        // We are in SSO mode, fetch TISP token and redirect to app
        const fetchToken = async () => {
          await fetchAndStoreTokenUsingIdToken(getIdTokenData().idToken, useScope);
          return redirectToApp(redirectUrl);
        };
        if (!verifySessionIdToken()) {
          await refreshIDToken();
        }
        return await fetchToken();
      }
    } else {
      // TISP mode, redirect to IDP authorize
      console.debug('ccauth', 'authorizeWithScope', 'user is TISP user');
      setTimeout(() => window.location.replace(buildAuthorizeUrl(useScope, redirectUrl)), 50);
      /*
      const loc = await createAuthIframeCall(buildAuthorizeUrl(useScope, redirectUrl), true);
      window.location.replace(loc.href);

       */
    }
  } catch (error) {
    // Error causes redirect to login app, TnC error should trigger TnC popup
    console.error('ccauth', 'authorizeWithScope', 'authorizeWithScope error!', error.toString());
    redirectCallback(error, redirectUrl);
  }
  return true;
};

const makeAuthorizeWithScope =
  ({
    redirectToApp,
    redirectToLogin,
    getAzureAuthorizeURL,
    validateRedirectUrl,
    fetchAndStoreTokenUsingIdToken,
    refreshIDToken,
    buildAuthorizeUrl,
    createAuthStore,
  }) =>
  (scope, redirectUrl, codeChallenge = '', redirectCallback = redirectToLogin) =>
    authorizeWithScope({
      scope,
      redirectUrl,
      codeChallenge,
      redirectToApp,
      redirectCallback,
      getAzureAuthorizeURL,
      validateRedirectUrl,
      fetchAndStoreTokenUsingIdToken,
      refreshIDToken,
      buildAuthorizeUrl,
      createAuthStore,
    });

export default makeAuthorizeWithScope;
