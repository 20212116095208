import { getStoredRedirectUrl, isLoginPath, isExcludedAuthPath, utilWildcard } from '../utils';
import InvalidRedirectError from '../errors/invalidRedirectError';

const verifyAndEncodeRedirectUrl = ({ redirectUrl, redirectUrlFromParams, allowedRedirects }) => {
  // TODO: Should be easy to clean but have no internet atm.
  const fallbackUrl = `${location.origin}${isExcludedAuthPath() || isLoginPath() ? '/' : location.pathname}${location.search}`;

  const selectedRedirectUrl = redirectUrl || getStoredRedirectUrl() || redirectUrlFromParams || fallbackUrl;

  console.debug('ccauth', 'validateRedirectUrl', redirectUrl, selectedRedirectUrl, redirectUrlFromParams, allowedRedirects);

  const redirect = decodeURIComponent(selectedRedirectUrl);

  /**
   * Check if redirect is external; if so make sure its allowed.
   */
  if (!redirect.startsWith(location.origin)) {
    if (!allowedRedirects || !allowedRedirects.find((allowedRedirect) => utilWildcard(redirect, allowedRedirect))) {
      throw new InvalidRedirectError(redirect);
    }
  }

  if (!redirect?.startsWith('https://')) {
    return encodeURIComponent(redirect);
  }

  const url = new URL(redirect);
  const params = url.searchParams;
  params.delete('error');
  params.delete('code');
  console.debug('ccauth', 'verifyAndEncodeRedirectUrl', 'got redirectUrl', url.toString());
  return encodeURIComponent(url.toString());
};

const makeVerifyAndEncodeRedirectUrl = ({ redirectURI, allowedRedirects }) => {
  return (redirectUrl = null) =>
    verifyAndEncodeRedirectUrl({
      redirectUrl,
      redirectUrlFromParams: redirectURI,
      allowedRedirects,
    });
};

export default makeVerifyAndEncodeRedirectUrl;
