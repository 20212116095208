const redirectToApp = ({ validateRedirectUrl, redirectUrl }) => {
  const redirect = validateRedirectUrl(redirectUrl);
  console.debug('ccauth', 'redirectToApp', 'redirect', redirect);
  window.location.replace(decodeURIComponent(redirect));
  return redirect;
};

const makeRedirectToApp =
  ({ validateRedirectUrl }) =>
  (redirectUrl) =>
    redirectToApp({ validateRedirectUrl, redirectUrl });

export default makeRedirectToApp;
