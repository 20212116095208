import { base64urlEncode, generateCodeChallengeAndStoreVerifier, getTokenData } from '../utils';

const makeRedirectToAuthorize = (origin) => {
  return (redirectUrl) =>
    redirectToAuthorize({
      origin,
      redirectUrl,
    });
};

const redirectToAuthorize = async ({ origin, redirectUrl }) => {
  console.debug('ccauth', 'redirectToAuthorize', 'init', redirectUrl);
  //  const isTISPLogin = getAuthMethod() === TISP_LOGIN;

  let codeChallenge = '';

  // If we are outside the portal create code_challenge in case of implicit login or e.g. VBC
  if (!location.origin.startsWith(origin)) {
    codeChallenge = `&code_challenge=${base64urlEncode(await generateCodeChallengeAndStoreVerifier())}`;
  } // convert bytes to string

  const destinationRoot = `${origin}/login/authorize`;
  const redirectParam = `?redirect_uri=${redirectUrl}`;
  const destination = `${destinationRoot}${redirectParam}${codeChallenge}`;

  console.debug('ccauth', 'redirectToAuthorize', 'destination', destination);

  setTimeout(() => {
    location.replace(destination);
  }, 50);

  return getTokenData();
};

export default makeRedirectToAuthorize;
