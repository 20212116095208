/* eslint-disable */
import ccauth from './auth';

if(!window.ccsdkGlobals) {
  window.ccsdkGlobals = {}
}


/****************************
 * This is for managing the brand selection page, probably it should be configurable in the future
 */

/* global CLOUDFRONT_VIEWER_COUNTRY */
window.ccbrand = (function (country) {
  const hostss = {
    vt: 'volvoconnect.com',
    ud: 'connect.udtrucks.com.au',
    mack: 'connect.macktrucks.com.au',
  };

  const setChoosenBrand = (brand) => {
    localStorage.setItem('ccbrand-value', brand);
  };

  const getChoosenBrand = () => localStorage.getItem('ccbrand-value');

  const redirectToBrand = (brand, pathname, search) => {
    const brandHost = hostss[brand];
    window.location.replace(`https://${brandHost}${pathname || ''}${search?.replace(location.host, brandHost) || ''}`);
  };

  const checkStoredBrand = (country) => {
    const ccbrandValue = getChoosenBrand();
    console.debug('ccauth', 'brand', country, ccbrandValue, location.pathname, location.origin.indexOf(hostss[ccbrandValue]));

    if (country === 'AU' && location.host.indexOf('volvoconnect.com') >= 0 && location.pathname !== '/login/portalSelector') {
      console.debug('ccauth', 'brand', 'in managed region', location.origin.indexOf(hostss[ccbrandValue]));
      //Removing false will make portal selector to show for everyone in AU, we will do it after UAT tested
      if (!ccbrandValue)
        window.location.replace(
            `${location.origin}/login/portalSelector?pathname=${encodeURIComponent(location.pathname)}&search=${encodeURIComponent(location.search)}`,
        );
      else if (location.origin.indexOf(hostss[ccbrandValue]) < 0 && ccbrandValue) redirectToBrand(ccbrandValue, location.pathname, location.search);
    }
  }

  return {
    setChoosenBrand,
    redirectToBrand,
    checkStoredBrand,
  };
})();

if(typeof CLOUDFRONT_VIEWER_COUNTRY !== 'undefined')
  ccbrand.checkStoredBrand(CLOUDFRONT_VIEWER_COUNTRY);

/**********************************
 * The CCAUTH2 system
 */

// create namespace
window.ccsdkGlobals.ccauthInit = async () => ccauth();
