import { getAuthMethod, setAuthMethod } from '../utils';

const hashFromString = (string) =>
  string.split('').reduce((a, b) => {
    const c = (a << 5) - a + b.charCodeAt(0);
    return c & c;
  }, 0);
const getValueForKey = (hashKey, key, defaultValue = '""') => JSON.parse(localStorage.getItem(`${hashKey}-${key}`) || defaultValue);
const setValueForKey = (hashKey, key, value) => localStorage.setItem(`${hashKey}-${key}`, JSON.stringify(value));
const removeValueForKey = (hashKey, key) => localStorage.removeItem(`${hashKey}-${key}`);
const updatePrevOrganizationList = (org, hashKey) => {
  const prevOrganizationIdList = getValueForKey(hashKey, 'prev-organization-list') || [];
  const filteredPrevOrganizationIdList = prevOrganizationIdList.filter(
    ({ platformFleetOrganizationIdentifier }) => org.platformFleetOrganizationIdentifier !== platformFleetOrganizationIdentifier,
  );
  filteredPrevOrganizationIdList.unshift(org);

  // To prevent the organisation list to grow to long remove the oldest once if we reach more than 9.
  if (filteredPrevOrganizationIdList.length > 9) {
    filteredPrevOrganizationIdList.pop();
  }
  setValueForKey(hashKey, 'prev-organization-list', filteredPrevOrganizationIdList);
};

/**
 * If changes to how the data is stored in local storage the STORE_VERSION needs to be updated!
 */
const STORE_VERSION = 'v1.4';

const createAuthStore = ({ username, clientId } = {}) => {
  console.debug('ccauth', 'createAuthStore', 'username', username, 'clientId', clientId);

  let hashKey = '';

  if (username) {
    const userHashKey = `ccauth-hash-${hashFromString(username)}`;
    hashKey = `${userHashKey}-${clientId}`;
    localStorage.setItem('ccauth-current-hash', hashKey);
  } else {
    hashKey = localStorage.getItem('ccauth-current-hash') || `noname-${clientId}`;
  }

  const storedVersion = getValueForKey(hashKey, 'store-version');
  if (storedVersion !== STORE_VERSION) {
    Object.keys(localStorage).forEach((key) => key.includes(hashKey) && localStorage.removeItem(key));
    setValueForKey(hashKey, 'store-version', STORE_VERSION);
  }

  // TODO: clean unused functions
  return {
    /* Necessary for supporting tools not yet updated */
    getPlatformUserIdentifier: () => getValueForKey(hashKey, 'platform-user-identifier'),
    setPlatformUserIdentifier: (value) => setValueForKey(hashKey, 'platform-user-identifier', value),
    getSelectedOrgId: () => getValueForKey(hashKey, 'selected-org-id'),
    setSelectedOrgId: (value) => setValueForKey(hashKey, 'selected-org-id', value),
    removeSelectedOrgId: () => removeValueForKey(hashKey, 'selected-org-id'),
    getPrevOrganizationList: () => getValueForKey(hashKey, 'prev-organization-list'),
    updatePrevOrganizationList: (org) => updatePrevOrganizationList(org, hashKey),
    getMarket: () => getValueForKey(hashKey, 'market'),
    setMarket: (value) => setValueForKey(hashKey, 'market', value),
    getClientId: () => getValueForKey(hashKey, 'clientId'),
    setClientId: (value) => setValueForKey(hashKey, 'clientId', value),
    removeClientId: () => removeValueForKey(hashKey, 'clientId'),
    getIdpKey: () => getValueForKey(hashKey, 'idpKey'),
    setIdpKey: (value) => setValueForKey(hashKey, 'idpKey', value),
    removeIdpKey: () => removeValueForKey(hashKey, 'idpKey'),

    // OLD STUFF remove later
    getAuthMethod,
    setAuthMethod,
    clearKey: () => localStorage.removeItem('ccauth-current-hash'),
  };
};

export const clearStatus = () => {
  // Clear stored client id
  const store = createAuthStore();
  store.removeClientId();
  store.removeIdpKey();
  store.clearKey();
  // Find any session token and remove it from session
  sessionStorage.removeItem('sessionToken');
  localStorage.removeItem('ccauth-last-token-scope');
  // Remove id token session
  sessionStorage.removeItem('sessionIDToken');
  // Remove any stored redirects
  sessionStorage.removeItem('ccauth-redirect-uri');
  // Remove any stored redirects
  localStorage.removeItem('auth-method');
  // In case we are in AZURE mode we need to clear it, used by admin
  localStorage.removeItem('AZURE');
};

export const makeCreateAuthStore = (clientId) => {
  return (username) => createAuthStore({ username, clientId });
};
