const makeAdobeAnalytics = () => {
  const sendDeferredEvent = (event) => {
    console.debug('ccauth', 'analytics', 'sending deferred event', event);
    window.ccsdkGlobals.adobe.deferredEvents.push(event);
  };

  const sendDeferredLoginSuccessfulEvent = () => {
    const event = {
      name: 'Login_SignIn_Success',
      parent: 'login',
      type: 'auto event',
      tool: 'login',
    };

    sendDeferredEvent(event);
  };

  return {
    sendDeferredLoginSuccessfulEvent,
    sendDeferredEvent,
  };
};

export default makeAdobeAnalytics;
