// const getRedirectUrl = () => `${window.location.origin}${window.location.pathname}`;
import { getTokenData, getAuthMethod, getStoredRedirectUrl } from '../utils';
import { TISP_LOGIN } from '../consts';

const makeRedirectToLogin = (origin) => {
  return (error, redirectUrl, codeChallenge) =>
    redirectToLogin({
      error,
      origin,
      redirectUrl,
      codeChallenge,
    });
};

const redirectToLogin = async ({ error, origin, redirectUrl, codeChallenge }) => {
  console.debug('ccauth', 'redirectToLogin', 'init', error);
  const isTISPLogin = getAuthMethod() === TISP_LOGIN;
  let errorParam = '';

  if (error) {
    const isSecurityError = error && error.toString().includes('SecurityError');
    const isCCAUTHIDPRedirectError = error && error.toString().includes('CCAUTHIDPRedirectError');
    if (!isSecurityError && (isTISPLogin || !isCCAUTHIDPRedirectError)) {
      console.error('cauth', 'redirectToLogin', error.toString(), error.stack);
      errorParam = `&error=${error.toString()}`;
    }
  }

  let codeChallengeParam = '';

  // if we have a client codeChallenge send it to login
  if (codeChallenge) {
    codeChallengeParam = `&code_challenge=${codeChallenge}`;
  } // convert bytes to string

  const destinationRoot = `${origin}/login`;
  const redirectParam = `?redirect_uri=${encodeURIComponent(redirectUrl || getStoredRedirectUrl() || location.href)}`;
  const destination = `${destinationRoot}${redirectParam}${errorParam}${codeChallengeParam}`;

  setTimeout(() => {
    location.replace(destination);
  }, 50);

  return getTokenData();
};

export default makeRedirectToLogin;
