import FetchTokenError from '../errors/fetchTokenError';
import { verifyState, setLastTokenScope } from '../utils';
import { fireEvent } from './events';

const fetchAndStoreTokenUtil = async ({ clientId, idpKey, params, idp, endpoint }) => {
  const formBody = [`client_id=${clientId}`, ...Object.entries(params).map(([key, value]) => `${key}=${value}`)];

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    Accept: 'application/json',
  };

  if (idpKey) {
    headers.Authorization = `Basic ${idpKey}`;
  }

  const response = await fetch(`${idp}${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: formBody.join('&'),
  });

  if (response.ok) {
    const { expires_in, access_token, scope } = await response.json();
    const tokenRegistered = new Date().toISOString();
    const expiresAt = new Date(new Date(tokenRegistered).getTime() + parseInt(expires_in, 10) * 1000).getTime();
    const tokenData = {
      accessToken: access_token,
      expiresIn: expires_in,
      expiresAt,
      scope,
      tokenRegistered: new Date().toISOString(),
    };

    sessionStorage.setItem('sessionToken', JSON.stringify(tokenData));

    fireEvent('newTokenFetched', tokenData);

    return tokenData;
  }

  const { error_description } = await response.json();
  console.error('ccauth', 'fetchAndStoreTokenUtil', `fetching ID token error: ${error_description}, redirect to login!`);
  throw new FetchTokenError(error_description);
};

const makeFetchAndStoreToken = (config, redirectToLogin) => (params, state, endpoint) => {
  /* Need to fix this later since it does mot work with SSO */
  // eslint-disable-next-line no-constant-condition
  if (/* state && !verifyState(state) */ false) {
    console.error(
      'ccauth',
      'fetchAndStoreToken',
      'You are most likely getting hacked, logging out!',
      state,
      sessionStorage.getItem('ccauth-state'),
      verifyState(state),
    );
    return redirectToLogin();
  }
  const { clientId, idp, clientSecret } = config;
  return fetchAndStoreTokenUtil({ clientId, idp, idpKey: clientSecret && btoa(`${clientId}:${clientSecret}`), params, endpoint });
};

const makeFetchFunctions = (config, redirectToLogin) => {
  const fetchAndStoreToken = makeFetchAndStoreToken(config, redirectToLogin);
  const fetchAndStoreTokenUsingCode = async (redirectUrl, code, state) => {
    const tokenData = await fetchAndStoreToken(
      {
        redirect_uri: redirectUrl,
        code,
        grant_type: 'authorization_code',
      },
      state,
      '/oauth/token',
    );
    // Yet another scope in localstorage, necessary for SSO on non customer connect sites since they will not have auth store from login app, used in manageToken
    const { scope } = tokenData;
    if (scope !== 'default') {
      setLastTokenScope(scope);
    }
    return tokenData;
  };

  const fetchAndStoreTokenUsingIdToken = (idToken, scope, state) => {
    // Yet another scope in localstorage, necessary for SSO on non customer connect sites since they will not have auth store from login app, used in manageToken
    if (scope !== 'default') {
      setLastTokenScope(scope);
    }
    return fetchAndStoreToken(
      {
        assertion: idToken,
        scope,
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
      },
      state,
      '/token.oauth2',
    );
  };

  return { fetchAndStoreTokenUsingCode, fetchAndStoreTokenUsingIdToken };
};

export default makeFetchFunctions;
